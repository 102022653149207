/* src/stylesheets/index.css */

html,
body,
#root {
  font-family: system-ui;
  height: 100%;
  width: 100%;
  margin: 0;
}

* {
  backface-visibility: hidden;
  border: none;
  box-shadow: none;
  box-sizing: border-box;
  font-family: system-ui;
  font-weight: 500;
  margin: 0;
  outline: 0;
  padding: 0;
  -webkit-font-smoothing: subpixel-antialiased;
}

.color-empty {
  background-color: #f2f2f2;
}

.color-empty.dark-mode {
  background-color: #333;
}

.color-scale-0 {
  background-color: #ebedf0;
}
.color-scale-1 {
  background-color: #c6e48b;
}
.color-scale-2 {
  background-color: #7bc96f;
}
.color-scale-3 {
  background-color: #239a3b;
}
.color-scale-4 {
  background-color: #196127;
}

.color-yes {
  background-color: #7bc96f;
}

.color-no {
  background-color: #ff8080;
}

.month .react-calendar-heatmap {
  width: 90px;
}

.react-calendar-heatmap .color-empty {
  fill: #f2f2f2;
}

.react-calendar-heatmap .color-empty.dark-mode {
  fill: #333;
}

.react-calendar-heatmap .color-scale-0 {
  fill: #ebedf0;
}
.react-calendar-heatmap .color-scale-1 {
  fill: #c6e48b;
}
.react-calendar-heatmap .color-scale-2 {
  fill: #7bc96f;
}
.react-calendar-heatmap .color-scale-3 {
  fill: #239a3b;
}
.react-calendar-heatmap .color-scale-4 {
  fill: #196127;
}

.react-calendar-heatmap .color-yes {
  fill: #7bc96f;
}

.react-calendar-heatmap .color-no {
  fill: #ff8080;
}

.react-calendar-heatmap rect:hover {
  stroke: transparent;
  stroke-width: 0;
}

.react-calendar-heatmap-month-label {
  font-size: 0.75rem !important;
}

.DayPicker-Day--today {
  color: #7bc96f;
}

.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: #C8E6C9;
}

.mobile-text {
  display: none !important;
}

.home .content {
  flex-direction: row !important;
}

.home .inner-footer {
  display: none !important;
}

.home .footer {
  display: flex !important;
}

.events {
  flex-wrap: wrap !important;
  justify-content: space-between !important;
}

.event {
  width: calc(50% - 8px) !important;
}

@media only screen and (max-width: 900px) {
  a,
  button {
    background-color: transparent !important;
    font-size: 1rem !important;
  }

  div {
    font-size: 1rem !important;
  }

  svg {
    top: 0 !important;
  }

  p,
  h1 {
    font-size: 1rem !important;
  }

  input,
  label {
    padding: 8px !important;
  }

  .button {
    min-width: 40px !important;
    padding: 0.5rem !important;
  }

  .mobile-column {
    flex-direction: column !important;
  }

  .mobile-text {
    display: inline !important;
  }

  .web-text {
    display: none !important;
  }

  .ios-app {
    margin-bottom: 1rem;
  }

  .home .content {
    flex-direction: column !important;
  }

  .home .title {
    font-size: 4rem !important;
  }

  .home .apps {
    flex-direction: column !important;
  }

  .home .inner-footer {
    align-items: center;
    display: flex !important;
    flex-direction: column;
  }

  .home .inner-footer a,
  .home .inner-footer button {
    margin: 0;
  }

  .home .footer {
    display: none !important;
  }

  .react-calendar-heatmap-month-label {
    font-size: 0.5rem !important;
  }

  .events {
    flex-direction: column !important;
  }

  .event {
    width: 100% !important;
  }
}
